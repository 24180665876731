<template>
  <BaseModalContent
    :dynamic="true"
    @close="$emit('close')">
    <div class="mb-l">
      <h4 class="text-size-h2 text-bold mb-s">Пригласить наставников</h4>
      <p>
        Скопируйте ссылку-приглашение для наставников и&nbsp;передайте
        ее&nbsp;преподавателям вашей школы. После регистрации по&nbsp;этой
        ссылке наставники будут автоматически привязаны к&nbsp;вашей школе.
        Важно, чтобы ваши преподаватели регистрировались именно по&nbsp;этой
        ссылке.
      </p>
      <p class="mb-m mt-s">
        Наставники могут следить за&nbsp;прогрессом участников НТО&nbsp;&mdash;
        например, они видят, какие задания вызывают сложности и&nbsp;каким темам
        следует уделить больше внимания при подготовке.
      </p>
      <div class="mt-m">
        <div v-if="inviteUrl">
          <CopyInput :value="inviteUrl" />
        </div>
      </div>
    </div>
    <div class="mb-l">
      <h2 class="text-bold text-size-h4 mb-m">
        Список наставников школы &laquo;{{ orgName }}&raquo;
      </h2>
      <div v-if="listMentors.length">
        <div
          v-for="mentor in listMentors"
          :key="mentor.id"
          class="st-card">
          <div class="st-card__avatar">
            <BaseAvatar :image="mentor.avatar" />
          </div>
          <div class="st-card__data">
            <div class="st-card__name">{{ mentor.fullName }}</div>
            <div class="st-card__email">{{ mentor.email }}</div>
            <div
              v-if="mentor.is_owner"
              class="color-meta">
              Владелец организации
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Список наставников пуст.</p>
      </div>
    </div>
    <BaseButton @click="$emit('close')">Закрыть</BaseButton>
  </BaseModalContent>
</template>

<script>
import CopyInput from "@/components/CopyInput";
export default {
  name: "PrincipalMentorsModal",
  components: { CopyInput },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    inviteUrl: String,
    mentors: {
      type: Array,
    },
  },
  computed: {
    orgName() {
      return this.organization.full_name || this.organization.short_name;
    },
    listMentors() {
      const { mentors } = this;
      if (!mentors?.length) return [];
      return mentors.map((n) => {
        return {
          id: n.id,
          email: n.user?.email,
          avatar: n.user?.avatar,
          is_owner: n.is_owner,
          fullName: [
            n.user?.first_name,
            n.user?.middele_name,
            n.user?.last_name,
          ]
            .filter(Boolean)
            .join(" "),
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.st-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  &__avatar {
    width: 40px;
    flex-grow: 0;
    min-width: 0;
    margin-right: 10px;
  }

  &__data {
    flex-grow: 1;
  }

  &__name {
    font-weight: bold;
  }
}
</style>
