import { request } from "@/services/api";

export const getAllItems = async (config = {}) => {
  if (!config.url) {
    throw new Error("Url is required");
  }
  const size = config.params?.size || 50;
  const currentConfig = {
    ...config,
    method: "GET",
    params: {
      ...config.params,
      page: 1,
      size,
    },
  };

  const { data: firstResponse } = await request(currentConfig);

  if (firstResponse.total < size) {
    return firstResponse.items;
  }

  const pages = Math.ceil(firstResponse.total / size);
  const requests = [];
  let result = [...firstResponse.items];

  for (let i = 2; i <= pages; i++) {
    currentConfig.params.page = i;
    requests.push(request(currentConfig));
  }

  (await Promise.all(requests)).forEach(({ data }) => {
    result = [...result, ...data.items];
  });

  return result;
};
