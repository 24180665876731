<template>
  <ValidationObserver
    ref="form"
    tag="form"
    @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-5 col-sm-6 mb-m">
        <label class="form-label">Название организации</label>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="{ required: true, min: 3, max: 255 }">
          <BaseInput
            v-model="org.full_name"
            theme="reg"
            :errors="errors" />
        </ValidationProvider>
      </div>
      <div class="col-md-4 col-sm-6 mb-m">
        <label class="form-label">Город/Населенный пункт</label>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required">
          <AddressAutocomplete
            v-model="org.address"
            :errors="errors"
            :search-options="{
              from_bound: { value: 'city' },
              locations: [{ country: '*' }],
              to_bound: { value: 'settlement' },
            }"
            theme="reg" />
        </ValidationProvider>
      </div>
    </div>
    <hr class="hr" />
    <div>
      <div class="text-bold text-center mb-m">Контактное лицо организации:</div>
      <div class="row">
        <div class="col-md-4 mb-m">
          <label class="form-label">Фамилия</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="contact_last_name"
            :rules="{ required: true }">
            <BaseInput
              v-model="org.contact_last_name"
              :errors="errors"
              theme="reg"
              name="contact_last_name" />
          </ValidationProvider>
        </div>
        <div class="col-md-4 mb-m">
          <label class="form-label">Имя</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="contact_first_name"
            :rules="{ required: true }">
            <BaseInput
              v-model="org.contact_first_name"
              theme="reg"
              :errors="errors" />
          </ValidationProvider>
        </div>
        <div class="col-md-4 mb-m">
          <label class="form-label">Отчество</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="contact_middle_name"
            :rules="{ required: true }">
            <BaseInput
              v-model="org.contact_middle_name"
              theme="reg"
              :errors="errors" />
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mb-m">
          <label class="form-label">Электронная почта</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="contact_email"
            :rules="{ required: true, email: true }">
            <BaseInput
              v-model="org.contact_email"
              theme="reg"
              :errors="errors" />
          </ValidationProvider>
        </div>
        <div class="col-md-4 mb-m">
          <label class="form-label">Контактный телефон</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="contact_phone"
            :rules="{ required: true }">
            <BaseInput
              v-model="org.contact_phone"
              theme="reg"
              :errors="errors" />
          </ValidationProvider>
        </div>
      </div>
    </div>
    <hr class="hr" />
    <div
      v-if="error"
      class="form-error mb-m">
      {{ error }}
    </div>
    <BaseButton
      v-if="allowCancel"
      theme="reg"
      class="mr-m"
      @click.prevent="$emit('cancel')"
      >Вернуться к выбору</BaseButton
    >
    <BaseButton
      type="submit"
      :loading="pending"
      >Продолжить</BaseButton
    >
  </ValidationObserver>
</template>

<script>
import AddressAutocomplete from "@/components/autocomplete/AddressAutocomplete";
import { talentRequest } from "@/services/api";

export default {
  name: "OrgForm",
  components: { AddressAutocomplete },
  props: {
    allowCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pending: false,
      error: "",
      org: {
        full_name: "",
        address: "",
        contact_email: "",
        contact_phone: "",
        contact_first_name: "",
        contact_last_name: "",
        contact_middle_name: "",
      },
    };
  },
  computed: {
    talentUser() {
      return this.$store.state.user.talentUser;
    },
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const payload = { ...this.org };
      this.pending = true;
      this.error = "";
      try {
        const { data } = await talentRequest({
          method: "POST",
          url: `/api/users/${this.talentUser.id}/organizations/`,
          data: payload,
        });
        this.$emit("onSubmit", data);
      } catch (error) {
        const errorData = error.originalData;
        if (errorData && typeof error.originalData === "object") {
          const errors = Object.keys(error.originalData).reduce(
            (acc, key) => {
              if (key in payload) {
                acc.field_errors[key] = error.originalData[key];
              } else {
                acc.non_field_errors = true;
              }
              return acc;
            },
            {
              field_errors: {},
              non_field_errors: false,
            }
          );
          if (errors.non_field_errors) {
            this.error = error.message;
          } else {
            this.$refs.form.setErrors(errors.field_errors);
          }
        } else {
          this.error = error.message;
        }
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
