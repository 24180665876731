<template>
  <div>
    <ValidationObserver
      ref="form"
      tag="form"
      @submit.prevent="handleSubmit">
      <p class="mb-m text-bold">
        Укажите данные о юридическом лице организации
      </p>
      <div class="row">
        <div class="col-md-4">
          <Autocomplete
            v-model="searchOrg"
            :options="searchOrgResults"
            placeholder="Поиск по ИНН или названию организации"
            theme="reg"
            :throttle="600"
            :max-results="10"
            @onSearch="handleSearchOrg"
            @onSelect="handleSetOrgData" />
        </div>
        <div class="col-md-8">
          <div class="typography"></div>
          <p>
            Если вы&nbsp;не&nbsp;хотите заполнять поля ниже вручную, найдите
            свою школу в&nbsp;справочнике. Для этого начните вводить название
            школы в&nbsp;этом поле и&nbsp;выберите нужный вариант
            в&nbsp;выпадающем списке. Также вы&nbsp;можете ввести в&nbsp;это
            поле полный ИНН школы.
          </p>
        </div>
      </div>

      <hr class="hr" />
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12 mb-m">
          <label class="form-label">Полное название</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="name"
            :rules="{ required: true, min: 3, max: 255 }">
            <BaseInput
              v-model="form.name"
              :errors="errors"
              theme="reg"
              name="organization_name" />
          </ValidationProvider>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 mb-m">
          <label class="form-label">Сокращенное название</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="short_name"
            :rules="{ required: true, min: 3, max: 255 }">
            <BaseInput
              v-model="form.short_name"
              :errors="errors"
              theme="reg"
              name="organization_short_name" />
          </ValidationProvider>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12 mb-m">
          <label class="form-label">ИНН</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="inn"
            :rules="{ required: true }">
            <BaseInput
              v-model="form.inn"
              :errors="errors"
              theme="reg"
              name="inn" />
          </ValidationProvider>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 mb-m">
          <label class="form-label">КПП</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="kpp"
            :rules="{ required: true }">
            <BaseInput
              v-model="form.kpp"
              :errors="errors"
              theme="reg"
              name="kpp" />
          </ValidationProvider>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 mb-m">
          <label class="form-label">ОГРН</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="ogrn"
            :rules="{ required: true, integer: true }">
            <BaseInput
              v-model="form.ogrn"
              :errors="errors"
              theme="reg"
              name="ogrn" />
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <label class="form-label">Юридический адрес</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="legal_address"
            :rules="{ required: true, min: 3, max: 255 }">
            <BaseInput
              v-model="form.legal_address"
              :errors="errors"
              theme="reg"
              name="legal_address" />
          </ValidationProvider>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <label class="form-label">Фактический адрес</label>
          <ValidationProvider
            v-slot="{ errors }"
            name="fact_address"
            :rules="{ required: true, min: 3, max: 255 }">
            <BaseInput
              v-model="form.fact_address"
              :errors="errors"
              theme="reg"
              name="fact_address" />
          </ValidationProvider>
        </div>
      </div>
      <hr class="hr" />
      <div
        v-if="error"
        class="form-error mb-m">
        {{ error }}
      </div>
      <div>
        <BaseButton
          theme="reg"
          class="mr-m"
          @click.prevent="$emit('cancel')"
          >Вернуться к выбору</BaseButton
        >
        <BaseButton
          type="submit"
          :loading="pending"
          >Продолжить</BaseButton
        >
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { talentRequest } from "@/services/api";
import Autocomplete from "@/components/autocomplete/Autocomplete";
export default {
  name: "LegalEntityForm",
  components: { Autocomplete },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchOrg: "",
      searchOrgResults: [],
      error: "",
      pending: false,
      form: {
        name: "",
        short_name: "",
        inn: "",
        kpp: "",
        ogrn: "",
        legal_address: "",
        fact_address: "",
      },
    };
  },
  computed: {
    talentUser() {
      return this.$store.state.user.talentUser;
    },
  },
  methods: {
    handleSetOrgData(orgData) {
      const data = orgData?.value?.data;
      if (!data) return;
      this.form.name = data?.name?.full_with_opf;
      this.form.short_name = data?.name?.short_with_opf;
      if (data.inn) {
        this.form.inn = +data.inn;
      }
      if (data.kpp) {
        this.form.kpp = +data.kpp;
      }
      if (data.ogrn) {
        this.form.ogrn = +data.ogrn;
      }

      const address = data.address?.unrestricted_value;
      if (address) {
        this.form.legal_address = this.form.fact_address = address;
      }
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      this.error = "";
      if (!isValid) return;
      this.pending = true;
      try {
        const { data } = await talentRequest({
          method: "POST",
          url: `/api/users/${this.talentUser.id}/organizations/${this.organization.id}/legal_entity/`,
          data: this.form,
        });
        this.$emit("onSubmit", data.id);
      } catch (error) {
        const errorData = error.originalData;
        if (errorData && typeof error.originalData === "object") {
          const errors = Object.keys(error.originalData).reduce(
            (acc, key) => {
              if (key in this.form) {
                acc.field_errors[key] = error.originalData[key];
              } else {
                acc.non_field_errors = true;
              }
              return acc;
            },
            {
              field_errors: {},
              non_field_errors: false,
            }
          );
          if (errors.non_field_errors) {
            this.error = error.message;
          } else {
            this.$refs.form.setErrors(errors.field_errors);
          }
        } else {
          this.error = error.message;
        }
      }
      this.pending = false;
    },

    async handleSearchOrg(query) {
      const { data } = await talentRequest({
        method: "POST",
        url: "/api/suggests/party/",
        data: {
          query,
        },
      });
      if (Array.isArray(data)) {
        this.searchOrgResults = data.map((n) => {
          return {
            label: n.unrestricted_value,
            value: n,
          };
        });
      } else {
        this.searchOrgResults = [];
      }
    },
  },
};
</script>

<style></style>
