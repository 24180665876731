<template>
  <div class="container">
    <RoleCard role="mentor">
      <template #label>Регистрация Школы</template>
      <template #label_img>
        <img src="@/assets/images/reg_mentor.svg" />
      </template>
      <template #body>
        <div class="row">
          <div class="col-md-8 col-xs-12">
            Вы&nbsp;пытаетесь перейти по&nbsp;некорректной ссылке. Чтобы
            зарегистрировать школу, пожалуйста, обратитесь к&nbsp;региональному
            координатору НТО в&nbsp;вашем регионе и&nbsp;запросите у&nbsp;него
            корректную ссылку.
          </div>
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
import RoleCard from "@/components/registration/RoleCard";

export default {
  name: "PrincipalError",
  components: {
    RoleCard,
  },
  metaInfo() {
    return {
      title: "Директорам школ. Ошибка.",
    };
  },
};
</script>

<style></style>
