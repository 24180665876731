<template>
  <BaseModalContent
    :dynamic="true"
    @close="$emit('close')">
    <div class="mb-l">
      <h4 class="text-size-h4 text-bold mb-s">Пригласить учеников</h4>
      <p class="mb-m">
        Скопируйте ссылку-приглашение и&nbsp;отправьте ее&nbsp;учащимся вашей
        школы для регистрации на&nbsp;НТО. После регистрации по&nbsp;этой ссылке
        учащиеся будут автоматически привязаны к&nbsp;вашей школе
      </p>
      <div class="mt-m text-left">
        <div class="form-label text-bold mb-xss">Ссылка-приглашение</div>
        <div v-if="inviteUrl">
          <CopyInput
            :value="inviteUrl"
            class="mb-s" />
        </div>
      </div>
      <div>
        <a
          class="link"
          href="#"
          @click.prevent="handleDownloadMaterials"
          >Скачать постер-приглашение</a
        >
        на НТО для учеников вашей школы
      </div>
    </div>
    <div class="mb-l">
      <h2 class="text-bold text-size-h4 mb-m">
        Список участников НТО от школы &laquo;{{ orgName }}&raquo;
      </h2>
      <div
        v-if="students.length || seasons.length"
        class="row mb-s">
        <div
          v-if="students.length"
          class="col-md-5 col-xs-12">
          <BaseSelect
            v-model="grade"
            :options="gradeOptions"
            :allow-empty="false"
            preselect-first
            label="title"
            placeholder="Класс"
            open-direction="top"
            :searchable="false"
            class="mb-s"></BaseSelect>
        </div>
        <div
          v-if="seasons.length"
          class="col-md-5 col-xs-12">
          <BaseSelect
            v-model="season"
            :options="seasonOptions"
            label="title"
            track-by="value"
            :allow-empty="false"
            open-direction="top"
            placeholder="Выбрать сезон"
            preselect-first
            class="mb-s" />
        </div>
      </div>
      <div v-if="listStudents.length">
        <div
          v-for="student in listStudents"
          :key="student.id"
          class="st-card">
          <div class="st-card__avatar">
            <BaseAvatar :image="student.avatar" />
          </div>
          <div class="st-card__data">
            <div class="st-card__name">{{ student.fullName }}</div>
            <div>
              Класс: <span v-if="student.grade">{{ student.grade }}</span
              ><span
                v-else
                class="color-meta"
                >Нет данных</span
              >
            </div>
            <div class="st-card__email">{{ student.email }}</div>
          </div>
        </div>
      </div>
      <div v-else-if="!listStudents.length && !isEmptyFilters">
        <p>
          Нет учеников по выбранным параметрам. Попробуйте
          <a
            href="#"
            class="link link--pseudo"
            @click.prevent="handleResetFilter"
            >сбросить фильтры</a
          >.
        </p>
      </div>
      <div v-else-if="!listStudents.length">
        <p>Список учеников пуст.</p>
      </div>
    </div>
    <BaseButton @click="$emit('close')">Закрыть</BaseButton>
  </BaseModalContent>
</template>

<script>
import { saveAs } from "file-saver";
import * as Sentry from "@sentry/vue";
import CopyInput from "@/components/CopyInput";
import { GRADE_OPTIONS } from "@/constants";
import { clientApiRequest } from "@/services/api";

export default {
  name: "PrincipalStudentsModal",
  components: { CopyInput },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    students: {
      type: Array,
      default() {
        return [];
      },
    },
    seasons: {
      type: Array,
      default: () => [],
    },
    inviteUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      grade: null,
      season: null,
    };
  },
  computed: {
    isEmptyFilters() {
      return !this.grade?.value && !this.season?.value;
    },
    gradeOptions() {
      let existingGrades = {};

      this.students?.reduce((acc, value) => {
        if (value.education_grade) {
          acc[value.education_grade] = true;
        }
        return acc;
      }, existingGrades);

      const grades = GRADE_OPTIONS.filter((n) => {
        return existingGrades[n];
      }).map((grade) => {
        return {
          title: grade,
          value: grade,
        };
      });

      return [
        {
          title: "Все классы",
          value: null,
        },
        ...grades,
      ];
    },
    seasonOptions() {
      const studentsSeasons = this.students.reduce((acc, value) => {
        if (value.event) {
          acc[value.event] = true;
        }
        return acc;
      }, {});
      const allSeasonValue = { title: "Все сезоны", value: null };
      return this.seasons.reduce(
        (res, season) => {
          if (studentsSeasons[season.talent_event]) {
            res.push({
              title: season.name,
              value: season.talent_event,
            });
          }
          return res;
        },
        [allSeasonValue]
      );
    },
    orgName() {
      return this.organization.full_name || this.organization.short_name;
    },
    listStudents() {
      const { students, grade, season } = this;
      let result = [];
      if (!students?.length) return result;

      result = students.map((n) => {
        return {
          id: n.id,
          eventId: n.event,
          email: n.user?.email,
          avatar: n.user?.avatar,
          grade: n.education_grade,
          fullName: [
            n.user?.first_name,
            n.user?.middele_name,
            n.user?.last_name,
          ]
            .filter(Boolean)
            .join(" "),
        };
      });

      if (grade?.value) {
        result = result.filter((n) => n.grade === grade.value);
      }

      if (season?.value) {
        result = result.filter((n) => n.eventId === season.value);
      }

      return result.sort((a, b) =>
        b.grade > a.grade ? 1 : b.grade === a.grade ? 0 : -1
      );
    },
  },
  methods: {
    handleResetFilter() {
      this.grade = this.gradeOptions[0];
      this.season = this.seasonOptions[0];
    },
    async handleDownloadMaterials() {
      try {
        const { data } = await clientApiRequest({
          method: "POST",
          url: "/principal/materials",
          responseType: "blob",
          data: {
            invite_url: this.inviteUrl,
          },
        });
        saveAs(data, `Постер-приглашение для учеников.pdf`);
      } catch (error) {
        Sentry.captureException(error);
        this.showErrorModal({
          title: "Не удалось сформировать материалы",
          content: "Пожалуйста, сообщите нам об этой ошибке",
          url: "/principal/materials",
          status: error?.response?.status,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.st-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  &__avatar {
    width: 40px;
    flex-grow: 0;
    min-width: 0;
    margin-right: 10px;
  }

  &__data {
    flex-grow: 1;
  }

  &__name {
    font-weight: bold;
  }
}
</style>
