<template>
  <div>
    <section class="hero">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-8 col-md-offset-2 col-xs-12">
            <h2 class="p-h1">Добро пожаловать!</h2>
            <p class="p-text-large mb-l color-white">
              Эта страница предназначена для общеобразовательных школ.
            </p>
            <p class="p-text-large mb-l color-white">
              Спрос на&nbsp;техническое и&nbsp;инженерное образование
              в&nbsp;школах быстро растет. Как школе включиться в&nbsp;процесс
              подготовки нового поколения технологических лидеров? Как запустить
              технологический кружок? Как оценить результаты?
            </p>
          </div>

          <!-- <div class="q-panels">
            <div class="q-panels__item mb-l">
              <div>
                <img
                  src="@/assets/images/principal/cube-transparent.svg"
                  alt="Лампочка"
                />
                <div>Как запустить технологический кружок?</div>
              </div>
              <Corners />
            </div>
            <div class="q-panels__item mb-l">
              <div>
                <img src="@/assets/images/principal/doc.svg" alt="Планшет" />
                <div>Как оценить результаты?</div>
              </div>
              <Corners />
            </div>
          </div> -->

          <div class="col-md-8 col-md-offset-2 col-xs-12">
            <p class="p-text-large color-white mb-l">
              Здесь вы&nbsp;найдете всю необходимую информацию и&nbsp;материалы,
              которые помогут открыть в&nbsp;общеобразовательной школе
              технологическое направление, заинтересовать учеников
              и&nbsp;пригласить их&nbsp;на&nbsp;Национальную технологическую
              олимпиаду.
            </p>
            <BaseButton
              v-if="!talentUser"
              tag="a"
              size="xlg"
              :href="loginUrl"
              >Присоединиться</BaseButton
            >
          </div>

          <div
            v-if="currentOrg"
            class="col-md-8 col-md-offset-2 col-xs-12 mb-xxl">
            <div class="alert">
              <Corners wide />
              <div class="alert__icon">
                <img
                  src="@/assets/images/principal/study.svg"
                  alt="вопрос" />
              </div>
              <div class="alert__content">
                <div>Ваша школа:</div>
                <div class="p-text-large">
                  <a
                    :href="orgUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="org-card__title"
                    title="Перейти в кабинет организатора">
                    {{ currentOrg.full_name || currentOrg.short_name }}</a
                  >
                </div>
                <div
                  v-if="currentOrg.address"
                  class="p-text-medium">
                  {{ currentOrg.address }}
                </div>

                <div class="row">
                  <div class="col-md-6 mb-m">
                    <div class="p-count">
                      <div class="p-count__digits">
                        {{ studentsCount }}
                      </div>
                      <div class="p-count__title">
                        <BaseIcon glyph="person" /> {{ studentsCountLabel }}
                      </div>
                      <BaseButton
                        theme="primary"
                        class="mt-m"
                        size="lg"
                        @click.prevent="openStudentsModal"
                        >Пригласить участника</BaseButton
                      >
                      <div v-if="studentInviteLink">
                        <a
                          href="#"
                          download
                          class="download-btn"
                          @click.prevent="handleDownloadMaterials">
                          <BaseIcon
                            class="download-btn__icon"
                            glyph="file-pdf" />
                          Скачать постер-приглашение на&nbsp;НТО для учеников
                          вашей школы
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="p-count">
                      <div class="p-count__digits">
                        {{ mentorsCount }}
                      </div>
                      <div class="p-count__title">
                        <BaseIcon glyph="whistle" /> {{ mentorsCountLabel }}
                      </div>
                      <BaseButton
                        theme="primary"
                        class="mt-m"
                        size="lg"
                        @click.prevent="openMentorsModal"
                        >Пригласить наставника</BaseButton
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="dir-section">
      <div class="container text-center">
        <h2 class="p-h2">Урок НТО</h2>
        <div class="row">
          <div class="col-md-8 col-md-offset-2 col-xs-12">
            <div class="alert mb-l">
              <Corners wide />
              <div class="alert__icon">
                <img
                  src="@/assets/images/principal/question_circle.svg"
                  alt="вопрос" />
              </div>
              <div class="alert__content">
                <p class="p-text-medium mb-m">
                  Урок НТО&nbsp;&mdash; это набор методических материалов,
                  который дает возможность учителю организовать и&nbsp;провести
                  увлекательный урок о&nbsp;технологиях настоящего
                  и&nbsp;будущего
                </p>
                <p class="p-text-medium mb-m">
                  Материалы урока знакомят школьников с&nbsp;Национальной
                  технологической олимпиадой и&nbsp;другими проектами Кружкового
                  движения.
                </p>
                <p class="p-text-medium mb-m">
                  Урок НТО помогает детям понять, что в&nbsp;основе прорывных
                  технологий лежат глубокие знания в&nbsp;предметных областях.
                </p>
              </div>
            </div>
            <p class="p-text-large mb-xs color-white">
              Проведите ознакомительный урок НТО в вашей школе.
            </p>
            <BaseButton
              theme="primary"
              class="mt-m"
              size="xlg"
              tag="a"
              href="https://nti-lesson.ru/"
              target="_blank"
              rel="noopener noreferrer"
              >Урок НТО</BaseButton
            >
            <div
              v-if="studentInviteLink"
              class="download-section">
              <a
                href="#"
                download
                class="download-btn"
                @click.prevent="handleDownloadMaterials">
                <BaseIcon
                  class="download-btn__icon"
                  glyph="file-pdf" />
                Скачать постер-приглашение на&nbsp;НТО для учеников вашей школы
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container text-center">
        <h2 class="p-h2">Документы</h2>
        <div class="row mb-l">
          <div class="col-md-8 col-md-offset-2 col-xs-12">
            <template v-if="!currentOrg">
              <p class="p-text-large mb-l color-white">
                Зарегистрируйте свою школу, и&nbsp;мы&nbsp;создадим для вас
                подробные методические материалы с&nbsp;презентацией, макетами
                для печати и&nbsp;ссылками для регистрации ваших учащихся
                на&nbsp;НТО.
              </p>
              <p class="p-text-medium mb-l color-principal-blue-light">
                Заключив с&nbsp;нами соглашение о&nbsp;передаче персональных
                данных, вы&nbsp;также сможете получать более подробную
                информацию об&nbsp;учащихся вашей школы, участвующих в&nbsp;НТО.
              </p>

              <BaseButton
                v-if="!currentOrg"
                tag="a"
                size="xlg"
                :href="loginUrl"
                >Зарегистрировать школу</BaseButton
              >
            </template>

            <template v-else>
              <p class="p-text-large color-white mb-l">
                Заключив с&nbsp;нами соглашение о&nbsp;передаче персональных
                данных, вы&nbsp;также сможете получать более подробную
                информацию об&nbsp;учащихся вашей школы, участвующих в&nbsp;НТО.
              </p>

              <BaseButton
                tag="a"
                target="_blank"
                rel="noopener noreferrer"
                size="xlg"
                :href="agreementUrl"
                >Подписать соглашение</BaseButton
              >
            </template>
          </div>
        </div>
        <div></div>
      </div>
    </section>

    <section
      v-if="currentOrg"
      class="section">
      <div class="container text-center">
        <h2 class="p-h2">Для наставников и&nbsp;учителей</h2>
        <div class="row">
          <div class="col-md-8 col-md-offset-2 col-xs-12">
            <p class="p-text-large mb-l color-white">
              Попросите учителей вашей школы зарегистрироваться по&nbsp;ссылке
              ниже, и&nbsp;они станут наставниками. Наставники могут следить
              за&nbsp;прогрессом участников НТО&nbsp;&mdash; например, они
              видят, с&nbsp;какими заданиями возникают сложности и&nbsp;каким
              темам следует уделить больше внимания при подготовке.
            </p>

            <BaseButton
              v-if="metorInviteLink"
              size="xlg"
              class="mb-l"
              @click.prevent="openMentorsModal"
              >Пригласить наставника</BaseButton
            >
            <p class="p-text-medium color-principal-blue-light">
              Важно, чтобы ваши учителя регистрировались только по&nbsp;этой
              ссылке&nbsp;&mdash; так у&nbsp;нас сразу будет информация
              о&nbsp;том, что они работают в&nbsp;вашей школе.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  MENTOR_ROLE,
  MODAL_DYNAMIC_DEFAULTS,
  PRINCIPAL_CODE_KEY,
  PRINCIPAL_ORG_KEY,
} from "@/constants";
import { saveAs } from "file-saver";
import * as Sentry from "@sentry/vue";
import Corners from "@/components/Corners";
import { numCases } from "@/utils";
import { talentRequest, clientApiRequest } from "@/services/api";
import StudentsModal from "@/components/principal/StudentsModal.vue";
import MentorsModal from "@/components/principal/MentorsModal";
// import DirectionContent from "@/components/principal/DirectionContent";
import { getAllItems } from "@/services/utils/onti";
export default {
  name: "Principal",
  components: {
    Corners,
  },
  metaInfo() {
    return {
      title: "Директорам школ",
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.query[PRINCIPAL_CODE_KEY] && to.query[PRINCIPAL_ORG_KEY]) {
      return next();
    }
    next({
      name: "principal_error",
    });
  },
  data() {
    return {
      pending: true,
      // activeDir: 0,
      userInviteCode: "",
      organizations: [],
      studentsPending: false,
      students: [],
      mentors: [],
      studentsCount: 0,
      seasons: [],
    };
  },
  computed: {
    // activeDirData() {
    //   return this.directions[this.activeDir];
    // },
    loginUrl() {
      const next = encodeURIComponent(this.$route.fullPath);
      return `${this.$store.state.clientApiPrefix}/auth/go?next=${next}`;
    },
    agreementUrl() {
      const { currentOrg } = this;
      if (!currentOrg) return;
      return `${this.$store.state.talentURL}/org/organizations/${currentOrg.id}/information/agreement`;
    },
    orgUrl() {
      const { currentOrg } = this;
      if (!currentOrg) return;
      return `${this.$store.state.talentURL}/org/organizations/${currentOrg.id}`;
    },
    talentUser() {
      return this.$store.state.user.talentUser;
    },
    seasonEvent() {
      const { seasons } = this;
      const lastSeason = seasons.sort((a, b) => b.id - a.id)[0];
      return lastSeason?.talent_event;
    },
    currentOrg() {
      const orgId = +this.$route.query[PRINCIPAL_ORG_KEY];
      return this.organizations.find((n) => {
        return n.id === orgId || n.representatives?.includes(orgId);
      });
    },
    metorInviteLink() {
      const { currentOrg } = this;
      if (!currentOrg) return;
      return this.$store?.state.mentor.mentorInvites[currentOrg.id];
    },
    mentorsCount() {
      const { currentOrg } = this;
      if (!currentOrg) return 0;
      if (this.$store.getters["user/user"]?.role === MENTOR_ROLE) {
        return currentOrg.admin_users.length;
      }
      return currentOrg.admin_users.length - 1;
    },
    studentsCountLabel() {
      return numCases(
        ["участник НТО", "участника НТО", "участников НТО"],
        this.studentsCount
      );
    },
    mentorsCountLabel() {
      return numCases(
        ["наставник", "наставника", "наставников"],
        this.mentorsCount
      );
    },
    studentInviteLink() {
      const { currentOrg, userInviteCode, seasonEvent } = this;
      if (!currentOrg || !userInviteCode || !seasonEvent) return;
      return (
        window.location.origin +
        `/invite/school?org=${currentOrg.id}&code=${userInviteCode}&event=${seasonEvent}`
      );
    },
  },
  created() {
    this.init();
  },
  methods: {
    openStudentsModal() {
      this.$modal.show(
        StudentsModal,
        {
          organization: this.currentOrg,
          students: this.students,
          seasons: this.seasons,
          inviteUrl: this.studentInviteLink,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    openMentorsModal() {
      this.$modal.show(
        MentorsModal,
        {
          organization: this.currentOrg,
          inviteUrl: this.metorInviteLink,
          mentors: this.mentors,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    async handleDownloadMaterials() {
      try {
        const { data } = await clientApiRequest({
          method: "POST",
          url: "/principal/materials",
          responseType: "blob",
          data: {
            invite_url: this.studentInviteLink,
          },
        });
        saveAs(data, `Постер-приглашение для учеников.pdf`);
      } catch (error) {
        Sentry.captureException(error);
        this.showErrorModal({
          title: "Не удалось сформировать материалы",
          content: "Пожалуйста, сообщите нам об этой ошибке",
          report: true,
          url: "/principal/materials",
          status: error?.response?.status,
        });
      }
    },

    async getMyOwnOrgs() {
      const { data } = await talentRequest({
        url: `/api/users/${this.talentUser.id}/organizations/`,
        params: {
          limit: 50,
          offset: 0,
          is_owner: "True",
        },
      });
      this.organizations = data.results;
    },
    /** Получаем список всех организации пользователя */
    async getOrganizations() {
      await this.getMyOwnOrgs().catch((err) => {
        console.log("err", err);
      });
      if (!this.currentOrg) {
        this.$router.push({
          name: "principal_reg",
          query: this.$route.query,
        });
      } else {
        try {
          await this.$store.dispatch(
            "mentor/getMentorInviteLink",
            this.currentOrg.id
          );
          const { data } = await talentRequest({
            method: "POST",
            url: `/api/users/${this.talentUser.id}/organizations/${this.currentOrg.id}/member/invite/code/`,
            params: {
              without_mentor: true,
            },
            data: {
              event: this.seasonEvent,
            },
          });
          this.userInviteCode = data.code;
        } catch (error) {
          this.showErrorModal({
            title: "Не удалось получить ссылку-приглашение для учеников",
            message: error.message,
          });
        }
      }
    },
    async init() {
      const isParticipant = this.$store.getters["user/isParticipant"];
      if (isParticipant) {
        this.$router
          .replace({
            name: "user-index",
          })
          .catch((error) => {
            console.log("error", error);
          });
        return;
      }
      const { talentUser } = this;
      this.pending = true;
      await this.getSeasons();
      try {
        if (talentUser) {
          await this.getOrganizations();
          /**
           * Если есть подходящая организация, то запросим ее членов
           */
          if (this.currentOrg) {
            this.getMentors();
            this.getAllNTOStudents();
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.pending = false;
    },
    async getSeasons() {
      try {
        const seasons = await getAllItems({
          url: "/seasons",
          params: {
            page: 1,
            size: 40,
          },
        });
        this.seasons = seasons;
        return seasons;
      } catch (error) {
        console.log("error", error);
      }
    },
    /**
     * Получение списка наставников школы
     */
    async getMentors() {
      try {
        const { data } = await talentRequest({
          method: "GET",
          url: `/api/users/${this.talentUser.id}/organizations/${this.currentOrg?.id}/admins/`,
          params: {
            limit: 100,
            offset: 0,
          },
        });
        this.mentors = data.results;
      } catch (error) {
        this.showErrorModal({
          title: "Не удалось получить список наставников",
          message: error.message,
        });
      }
    },
    /**
     * Получение всех учащихся организации связанных с мероприятием НТО
     */
    async getAllNTOStudents() {
      const { seasons } = this;
      this.studentsPending = true;
      const chunkSize = 50;
      let events = this.seasonEvent;
      if (seasons.length) {
        events = seasons.map((n) => n.talent_event).join(",");
      }
      try {
        let results = [];
        const getConfig = (offset) => {
          return {
            url: `/api/users/${this.talentUser.id}/organizations/${this.currentOrg?.id}/member/`,
            params: {
              limit: chunkSize,
              offset,
              event: events,
              get_education_grade: true,
            },
          };
        };
        const { data } = await talentRequest(getConfig(0));
        this.studentsCount = data.count;
        results = [...data.results];
        // параллельно запросим остальные страницы, если есть
        if (data.count > chunkSize) {
          const chunks = Math.ceil(data.count / chunkSize) - 1;
          let requests = [];
          for (let i = 0; i < chunks; i++) {
            requests.push(talentRequest(getConfig(chunkSize * (i + 1))));
          }
          const responses = await Promise.all(requests);
          responses.forEach((response) => {
            results = [...results, ...response.data.results];
          });
        }
        this.students = results;
      } catch (error) {
        this.showErrorModal({
          title: "Не удалось получить список учеников",
          message: error.message,
        });
      } finally {
        this.studentsPending = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.p-count {
  font-size: 16px;
  margin-top: 1.3em;
  &__digits {
    font-size: 3.5em;
    line-height: 1;
  }

  &__title {
    font-size: 1.2em;
    margin-bottom: 0.4em;
  }

  &__link {
    color: currentColor;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #fff;
    }

    span {
      border-bottom: 1px dashed currentColor;
      transition: border 0.3s;
    }

    &:hover span {
      border-bottom-color: transparent;
    }
  }
}

.org-card {
  position: relative;
  background-color: @principal-blue;
  padding: 40px 60px;

  &__title {
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }

  .media-max-sm({
    padding: 20px 30px;
  });
}
.section {
  padding: 40px 0;

  .media-max-sm({
    padding: 25px 0;
  });
}
.dir-section {
  padding-top: 90px;
  padding-bottom: 40px;
  background: url("../assets/images/principal/group_light.svg") no-repeat -200px
    0;
}
.p-text-large {
  .fz(20);
  line-height: 30/22;
  .media-max-sm({
    .fz(18)
  });
}
.p-text-medium {
  .fz(18);
  line-height: 26/18;
  .media-max-sm({
    .fz(16)
  });
}
.p-mb-1 {
  margin-bottom: 30/22em;
}
.p-h1 {
  color: @principal-blue-light;
  .fz(60);
  margin-bottom: 30/60em;
  line-height: 66/60;
  font-weight: bold;

  .media-max-sm({
    .fz(40)
  });
}
.p-h2 {
  color: @principal-blue-light;
  .fz(44);
  margin-bottom: 30/44em;
  line-height: 50/44;
  font-weight: bold;
  .media-max-sm({
    .fz(24)
  });
}
.q-panels {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  width: 100%;

  .media-max-sm({
    flex-wrap: wrap;
  });

  &__item {
    width: 220px;
    background-color: #041b54;
    padding: 20px;
    margin: 0px 10px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: @principal-blue-light;
    .fz(18);
    text-align: left;
    img {
      max-width: 100%;
    }
  }
}

.alert {
  background-color: #041b54;
  position: relative;
  padding: 20px;
  color: @principal-blue-light;
  text-align: left;
  display: flex;
  flex-direction: row;

  &__icon {
    flex-grow: 0;
    margin-right: 20px;
    width: 32px;
    flex-shrink: 0;

    img {
      max-width: 100%;
    }
  }

  &__content {
    flex-grow: 1;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  &__nav {
    flex-grow: 0;
    width: 440px;
    flex-shrink: 0;
    text-align: left;
    &-item {
      display: flex;
      flex-direction: row;
      color: #fff;
      padding: 10px 30px 10px 10px;
      cursor: pointer;
      align-items: center;
      user-select: none;
    }

    &-text {
      max-width: 264px;
    }

    &-icon {
      flex-grow: 0;
      flex-shrink: 0;
      width: 64px;
      margin-right: 17px;
      position: relative;
      &::before {
        content: "";
        display: block;
        padding-top: 100%;
      }

      img {
        position: absolute;
        max-width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: opacity 0.3s;
      }

      img.active {
        opacity: 0;
      }
    }

    &-item:hover {
      background-color: lighten(@principal-blue, 5%);
    }

    &-item.is-active {
      background-color: @principal-blue;
    }
    &-item.is-active &-icon img,
    &-item:hover &-icon img {
      opacity: 0;
    }
    &-item.is-active &-icon img.active,
    &-item:hover &-icon img.active {
      opacity: 1;
    }
  }

  &__content {
    border: 1px solid @principal-blue;
    padding: 40px 50px;

    &--mobile {
      display: none;
    }

    &--desktop {
      @media screen and (max-width: 680px) {
        display: none !important;
      }
    }
  }

  @media screen and (max-width: 680px) {
    &__nav {
      width: 100%;
    }
    &__nav-item.is-active + &__content {
      display: block !important;
    }
    &__content {
      padding: 20px 30px;
    }
  }

  &__title {
    .fz(24);
    line-height: 30/24;
    font-weight: bold;
    color: @principal-blue-light;
    margin-bottom: 0.75em;
  }
}

.download-btn {
  margin-top: 2em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none;
  color: @principal-blue-light;

  &__icon {
    font-size: 1.32em;
    margin-right: 0.25em;
    flex-shrink: 0;
  }
}

.download-section {
  // max-width: 320px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  // text-align: left;
  .download-btn {
    justify-content: center;
  }
}
</style>
