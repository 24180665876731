<template>
  <div
    class="corners"
    role="presentation"
    aria-hidden>
    <span
      v-for="item in sides"
      :key="item"
      class="corner"
      :class="{ [`corner--${item}`]: true, 'corner--wide': wide }"></span>
  </div>
</template>

<script>
export default {
  name: "Corners",
  props: {
    sides: {
      type: Array,
      default() {
        return ["lt", "lb", "rb", "rt"];
      },
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
@corner-height: 16px;
@corner-width: 2px;

.corners {
  pointer-events: none;
}

.corner {
  position: absolute;
  background-color: #017aff;
  width: 0px;
  height: 0px;
  display: block;
  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::before {
    width: @corner-width;
    height: @corner-height;
    background-color: inherit;
  }

  &::after {
    width: @corner-height;
    height: @corner-width;
    background-color: inherit;
  }

  &--lt {
    top: @corner-width / -2;
    left: @corner-width / -2;

    &::before {
      left: 0;
      top: 0;
      border-bottom-right-radius: @corner-width / 2;
      border-bottom-left-radius: @corner-width / 2;
    }
    &::after {
      left: 0px;
      top: 0;
      border-top-right-radius: @corner-width / 2;
      border-bottom-right-radius: @corner-width / 2;
    }
  }

  &--lb {
    bottom: @corner-width / -2;
    left: @corner-width / -2;

    &::before {
      left: 0;
      bottom: 0;
      border-top-right-radius: @corner-width / 2;
      border-top-left-radius: @corner-width / 2;
    }
    &::after {
      left: 0px;
      bottom: 0;
      border-top-right-radius: @corner-width / 2;
      border-bottom-right-radius: @corner-width / 2;
    }
  }

  &--rb {
    bottom: @corner-width / -2;
    right: @corner-width / -2;

    &::before {
      right: 0;
      bottom: 0;
      border-top-right-radius: @corner-width / 2;
      border-top-left-radius: @corner-width / 2;
    }
    &::after {
      right: 0px;
      bottom: 0;
      border-top-left-radius: @corner-width / 2;
      border-bottom-left-radius: @corner-width / 2;
    }
  }

  &--rt {
    top: @corner-width / -2;
    right: @corner-width / -2;

    &::before {
      right: 0;
      top: 0;
      border-bottom-right-radius: @corner-width / 2;
      border-bottom-left-radius: @corner-width / 2;
    }
    &::after {
      right: 0px;
      top: 0;
      border-top-left-radius: @corner-width / 2;
      border-bottom-left-radius: @corner-width / 2;
    }
  }
}
</style>
